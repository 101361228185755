<template>
    <app-collapse accordion class="collapse-card my-page">
        <span class="collapse-card" :key="index" v-for="(item, index) in getMyPageList" v-bind:class="{ 'sub-menu-bottom-border': index !== myPage.length-1}">
            <!-- <div class="px-1"><hr class="line"/></div> -->
            <span v-if="item.children">
                <app-collapse-item :isVisible="item.children.find(x => x.id === myPortalActiveId) ? true:false" class="" :title="item.title" v-bind:class="{'text-secondary' : myPortalActiveId.includes(item.id)}">
                    <div :key="children.id" v-for="children in item.children">
                        <h4>
                            <a @click="onClickMyPortal(children)" class="font" v-bind:class="{'text-secondary' : children.id === myPortalActiveId}">{{children.title}}</a>
                        </h4>
                    </div>
                </app-collapse-item>
            </span>
            <span v-else @click="onClickMyPortal(item)">
                <app-collapse-item class="my-page-no-children" :title="item.title" v-bind:class="{'text-secondary' : item.id === myPortalActiveId || myPortalActiveId.includes(item.id)}">
                    <template #header >
                        <span class="d-flex lead collapse-title" v-if="item.id==='notification'&& $store.getters['unreadNotification/unreadNotification']>0">
                            {{item.title}}
                            <span style="width: 25px; height: 25px; aspect-ratio: 1" class="ml-1 dot d-flex justify-content-center align-items-center">{{getUnreadNotifications}}</span>
                            
                        </span>
                    </template>
                </app-collapse-item>
            </span>
        </span>
    </app-collapse>
</template>
<script>
    import AppCollapse from '@core/components/app-collapse/AppCollapse'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'

    export default {
        components: {
            AppCollapse,
            AppCollapseItem
        },
        data() {
            return {
                myPage: [
                    {id: 'your-units', title: 'Vaše enote', router_name: 'your-units'},
                    {id: 'card-owner-1', title: 'Obračun', router_name: 'card-traffic'},
                    {id: 'card-owner-2', title: 'Odprte postavke', router_name: 'open-items'},
                    {id: 'fund', title: 'Sklad', router_name: 'reserve-fund-funds'},
                    {id: 'manager-documents', title: 'Dokumenti upravnika', router_name: 'manager-documents'},
                    {id: 'readings', title: 'Odčitki', router_name: 'readings'},
                    {id: 'document', title: 'Dobavitelji', router_name: 'suppliers'},
                    {id: 'bulletin-board', title: 'Oglasna deska', router_name: 'bulletin-board'},
                    {id: 'notification', title: 'Obvestila', router_name: 'notifications'},
                    {id: 'agent-message', title: 'Obrazci in sporočila upravniku', router_name: 'agent_messages'},
                    {id: 'registered-number-of-people-and-dogs', title: 'Prijavljena št. oseb in psov', router_name: 'registered-number-of-people-and-dogs'},
                    {id: 'meeting', title: 'E-sestanki', router_name: 'meetings'}
                ],
                myPortalActiveId: ''
            }
        },
        methods: {
            onClickMyPortal(page) {
                if (this.$route.name === page.router_name) return
                this.myPortalActiveId = page.id
                this.$router.push({name: page.router_name})
            }
        },
        async beforeMount() {
            if (this.$route.meta.pageId !== null) {
                this.myPortalActiveId = this.$route.meta.pageId
            }
        },
        computed:{
            getUnreadNotifications() {
                return this.$store.getters['unreadNotification/unreadNotification']
            },
            getMyPageList() {
                if (this.$store.getters['user/userData'].manager !== 'habit') {
                    return this.myPage.filter(page => page.id !== 'readings')
                } else return this.myPage
            }
        }
    }
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans&display=swap');

  .collapse-card .card-header .lead svg {
    display: none !important;
  }

  .collapse-card .font{
    font-size: 15px !important;
    font-weight: normal;
  }

  .collapse-card.collapse-icon [data-toggle='collapse']:after{
    background-image: url('~@/assets/images/icons/chevron-down.svg') !important;
    background-size: 40px 15px;
  }

  .collapse-card.collapse-icon [aria-expanded=false]:after{
    transform: rotate(0deg) !important;
  }
  .collapse-card.collapse-icon [aria-expanded=true]:after{
    transform: rotate(180deg) !important;
  }

  .collapse-card .card .card-header{
    padding-left: 0px !important;
  }
  .collapse-card .card-body{
    border: none !important;
    font-family: 'Encode Sans', sans-serif !important;
    padding-top: 15px !important;
    padding-left: 0px !important;
  }
  .sub-menu-bottom-border .card-header{
    border-bottom: 1px solid black !important;
  }

.collapse-card .card-header  {
  border-radius: 0;
  font-family: 'Encode Sans', sans-serif;
  color: black !important;
}
.collapse-card .card-body {
  background-color: transparent !important;
  border: 2px solid #E6E6E6;
}
.collapse-card .dark-layout {
  background-color: #283046 !important;
}
.nocolapse .card-title, .nocolapse{
  background-color: #E6E6E6;
  margin-bottom: 0;
  border-radius: 0;
  font-weight: bold;
}
.collapse-card .collapse .card-body a {
  color: #009FD4;
}
.my-page .collapse .card-body a{
  color: black;
  text-transform: none;
}
.collapse-card .card {
  box-shadow: none;
}
.line {
  border-top: 1px solid white;
  margin: 0;
  padding: 0;
}
.listing_type .container p {
  color: #009FD4;
}

/* TOOLTIP */
.vue-slider {
  margin-top: 16px !important;
}
.vue-slider-dot-handle {
  background-color: #BFD42E;
  border: unset;
  box-shadow: unset;
}
.vue-slider-dot-tooltip-inner {
  border-color: unset;
  background-color: unset;
  box-shadow: unset;
  border-radius: unset;
  padding: 0;
}
.vue-slider-dot-tooltip-top {
  top: -5px;
}
.vue-slider-dot-tooltip-text {
  color: #BFD42E;
  font-weight: bold;
  font-size: 16px;
}
.vue-slider-rail {
  background-color: #E6E6E6;
  border: unset;
  box-shadow: unset;
}
.vue-slider-process {
  background-color: #BFD42E;
  border: unset;
  box-shadow: unset;
}
.vue-slider:hover .vue-slider-process {
  background-color: #BFD42E;
}
.my-page-no-children div div {
  display: none !important;
}
.my-page-no-children div:after {
  display: none !important;
}
.dot {
    color: white;
    display: inline-block;
    background-color: #72a5d8;
    border-radius: 50%;
    text-align: center;
}
</style>
